import React from 'react';
import './Resume.css';

const Resume = () => {
    return (
        <div className="resume">
            <header className="resume-header">
                <h1>Sanjeevlu Buggargani</h1>
                <p>ATL, GA | <a href="mailto:buggargs@gmail.com">buggargs@gmail.com</a> | <a href="https://github.com/nsanju0413">GitHub</a> | <a href="https://www.linkedin.com/in/nsanju0413/">LinkedIn</a> | <a href="https://www.bsanju.in/">Website</a></p>
            </header>

            <section className="resume-section">
                <h2>SUMMARY</h2>
                <p>Full Stack Developer with 2+ years of experience building scalable web and mobile applications. Knowledge in UI/frontend and backend designs of web development. Seeking to leverage technical skills to contribute to innovative projects.</p>
            </section>

            <section className="resume-section">
                <h2>PROFESSIONAL EXPERIENCE</h2>

                <div className="experience">
                    <h3>Android Developer | Fin Infotech | Hyderabad</h3>
                    <p>Sep 2023 – Present</p>
                    <ul>
                        <li>Develop custom Android applications for businesses and clients.</li>
                        <li>Deploy Android applications to the Google Play Store.</li>
                        <li>Expertise in Java and proficient with databases like SQLite, Firebase, and Realm.</li>
                        <li>Skilled in performance tuning, application testing, quality assurance, security, and deployment.</li>
                        <li>Experience with XML, JSON, REST APIs, and Retrofit for data integration.</li>
                    </ul>
                </div>

                <div className="experience">
                    <h3>CTK Associate | Amazon Development Center India | Hyderabad</h3>
                    <p>May 2023 – Aug 2023</p>
                    <ul>
                        <li>Contributed to a research project focused on improving user accessibility across platforms.</li>
                        <li>Developed Android, iOS, and web applications to test and validate research insights.</li>
                    </ul>
                </div>

                <div className="experience">
                    <h3>Software Developer | Stonedge Technologies and Robotics | Hyderabad, India</h3>
                    <p>Jan 2020 – Jan 2021</p>
                    <ul>
                        <li>Develop, edit, and maintain web pages on stonedgetech.com for maximum impact and alignment with campaign objectives, providing design and UX recommendations.</li>
                        <li>Host websites on cPanel and Cloud Hosting platforms.</li>
                        <li>Implement Google-based SEO and ad campaigns; develop Android applications for controlling vending machines via web integration.</li>
                    </ul>
                </div>
            </section>

            <section className="resume-section">
                <h2>PROJECTS</h2>

                <div className="project">
                    <h3>Learning Management System | Node.js, Express.js, Handlebars, MongoDB, GridFs Library</h3>
                    <ul>
                        <li>Led a team of 6 to architect and deliver an enterprise LMS platform serving 500+ students.</li>
                        <li>Built RESTful API backend and managed file storage/streaming with MongoDB and GridFS.</li>
                        <li>Designed intuitive, mobile-responsive UI/UX with micro-interactions and animations.</li>
                    </ul>
                </div>

                <div className="project">
                    <h3>Solar Construction Management Website | Express.js, Node.js, MongoDB, HTML, CSS, JavaScript</h3>
                    <ul>
                        <li>Designed and implemented an end-to-end web portal for a solar construction firm with 100+ employees.</li>
                        <li>Developed relational database schema to streamline data flow between cross-functional teams.</li>
                        <li>Optimized site performance resulting in a 25% increase in page load speed.</li>
                    </ul>
                </div>

                <div className="project">
                    <h3>Church Management Website | Node.js, Bootstrap, MongoDB</h3>
                    <ul>
                        <li>Developed a responsive single-page application with an admin dashboard for content management.</li>
                        <li>Integrated 3rd-party APIs like the Bible API to dynamically fetch and display content.</li>
                        <li>Wrote comprehensive technical docs to aid future feature development and maintenance.</li>
                    </ul>
                </div>

                <div className="project">
                    <h3>University Android App | Android Studio, Java, XML, Material Design, Firebase</h3>
                    <ul>
                        <li>Spearheaded development of university app, driving UI design and feature implementation.</li>
                        <li>Built functionalities including resume builder, GPA calculator, and cloud document storage.</li>
                        <li>Achieved 10,000+ user downloads, 4.5-star rating, and 50% improvement in user engagement metrics.</li>
                    </ul>
                </div>
            </section>

            <section className="resume-section">
                <h2>EDUCATION</h2>
                <p>Master of Science in Computer Science, GPA: 3.49 / 4.0 | Aug 2023 – Dec 2024</p>
                <p>Auburn University at Montgomery | Montgomery, AL</p>
                <p>Relevant Coursework: Web Programming, C++ Programming, Python, DSA, and Machine Learning</p>
            </section>

            <section className="resume-section">
                <h2>LEADERSHIP AND CERTIFICATIONS</h2>
                <ul>
                    <li>Project Management – Managed 2 development teams across 2 concurrent projects using Agile methodology.</li>
                    <li>Certifications – Java, Python, Angular, Full Stack Development, Android App Development.</li>
                </ul>
            </section>

            <section className="resume-section">
                <h2>SKILLS</h2>
                <p>Programming Languages: Java, Python</p>
                <p>Web Development: HTML5, CSS3, JavaScript, TypeScript, Ajax, jQuery, Bootstrap, JSON, RESTful API</p>
                <p>Frameworks: NodeJS, Angular, React, Android, XCode, WordPress</p>
                <p>Server Hosting: AWS, Firebase, Docker</p>
                <p>Database: MySQL, SQL, MongoDB</p>
                <p>Developer Tools: Git, GitHub, VS Code, PyCharm, IntelliJ, Android Studio</p>
            </section>
        </div>
    );
};

export default Resume;
