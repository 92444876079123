import React from 'react';
import './App.css';
import Resume from "./components/Resume/Resume";

function App() {
    return (
        <div className="App">
            <Resume />
        </div>
    );
}

export default App;
